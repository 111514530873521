<template>
  <v-select
    v-model="sFrecuency"
    :hint="$t('timeperiod.frequency.hint')"
    :items="headers"
    :label="$t('timeperiod.frequency')"
    :menu-props="{ offsetY: true }"
    dense
    outlined
    persistent-hint
  />
</template>

<script lang="ts">
import { Component, Mixins, VModel } from "vue-property-decorator";
import { PaymentTermFrequency } from "@planetadeleste/vue-mc-gw";
import DataTableMixin from "@/mixins/DataTableMixin";
import { DataTableHeader } from "vuetify";

@Component
export default class TimePeriodFrequency extends Mixins(DataTableMixin) {
  @VModel({ type: String, default: () => "month" })
  sFrecuency!: PaymentTermFrequency;

  frequencyItems: DataTableHeader[] = [
    { text: "day", value: "day" },
    { text: "week", value: "week" },
    { text: "month", value: "month" },
    { text: "year", value: "year" },
  ];

  mounted() {
    this.setDTHeaders(this.frequencyItems);
  }
}
</script>
